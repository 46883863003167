import React from 'react';

// Initializing the initial state for the context( if not, the build will break )
const initialState = {
	initiated: false,
	profile: false,
	location: false,
	equipment: false,
	extra: false,
	getMeals: [],
	rivers: [],
	ratecards: false,
}
const GuideContext = React.createContext(initialState);

export default GuideContext;
