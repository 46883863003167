import React, { Component } from 'react'
import { configureScope, captureMessage, captureException, showReportDialog } from '@sentry/browser';
import { isLoggedIn, getCurrentUser } from "../utils/Auth"
import { Button } from "react-bootstrap"

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null, hasError: false };
	}
	sentryIssue = (error, errorInfo) => {
		configureScope((scope) => {
			Object.keys(errorInfo).forEach(key => {
				scope.setExtra(key, errorInfo[key]);
				if (isLoggedIn() && getCurrentUser()) {
					scope.setUser({ "id": getCurrentUser().guid });
				}
			});
		});
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error,
			errorInfo
		});

		// checking if rendered page is fallback UI
		if (this.state.hasError) {
			// yes, creating an issue as page crash occured - (error msg) and attaching tags and level
			this.sentryIssue(error, errorInfo)
			captureMessage(`Page Crash Occured - ${error}`, {
				level: "critical",
				tags: { 'error_type': 'page_crash' },
			})
		} else {
			// no, creating an issue as normal error, without any tag or level
			this.sentryIssue(error, errorInfo)
			captureException(new Error(error))
		}
	}

	static getDerivedStateFromError(error) {
		//Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			// Error path
			return (
				<div style={{
					width: "fit-content",
					margin: "65px auto 45px",
					color: "#b65546"
				}}>
					<h2>Uh oh, looks like we are out fishing.</h2>
					<p>Please try and refresh while we come back with our catches to get this resolved.</p>

					<Button
						style={{
							borderColor: "#20b4e4",
							fontSize: "16px",
							backgroundColor: "#20b4e4 ",
							color: "#fff ",
							position: "relative",
							left: "40%",
							height: "35px",
							padding: "5px",
						}}
						onClick={() =>
							showReportDialog({ eventId: this.state.eventId })
						}
					>
						Report feedback
					</Button>

					{/* _____ERROR MSG FOR DEVELOPMENT PURPOSE_____ */}
					{/* <details style={{ whiteSpace: 'pre-wrap' }}>
						{this.state.error && this.state.error.toString()}
						<br />
						{this.state.errorInfo ? this.state.errorInfo.componentStack : null}
					</details> */}
				</div >
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

export default ErrorBoundary
