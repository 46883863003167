import React from 'react';


// Initializing the initial state for the context( if not, the build will break )
export const initialState = {
	location: {
		lat: 29.54496192932129,
		lng: -81.7627944946289,
	},
	guides: [],
	guidesWithinRange: [],
	guideDetail: [],
	datefromHome: null,
	activeTab: {},
	coordinates: {},
	count: "1",
	showCountError: false,
	selectedTime: "Fullday",
	startDate: null,
	endDate: new Date(),
	selectedWaterType: [],
	requireFn: false,
	requireLn: false,
	requireEmail: false,
	requirePhone: false,
	selectedFishingType: [],
	filtersearchselectedTime: [],
	isLocationDisable: false,
	toFitBoundCity: '',
	inputFocus: false,
	searchSelectedTripType: []
}
const MapContext = React.createContext(initialState);

export default MapContext;
