import { createContext } from "react"

export const initialState = {
	"searchQuery": {
		"address": null,
		"latitude": null,
		"longitude": null,
		"startDate": null,
		"guest": 1,
		"category": "alltrips",
		"min_rate": 0,
		"max_rate": null,
		"activity_type": null,
		"triptype": null,
		"min_time": null,
		"max_time": null,
		"species": null,
		"start_time": null,
		"benifits": null,
		"reviews": null,
		"languages": null,
		"ne_lat": null,
		"ne_lng": null,
		"sw_lat": null,
		"sw_lng": null,
		"zoom": null,
		"mapDrag": false,
		"page": 1,
	}
}
const SearchContext = createContext(initialState)

export default SearchContext
