import React from "react";

const initialState = {
    dashboard: false,
    trips: false,
    catches: false,
    spots: false,
    reports: false,
    pois: false,
    isLoading: true,
    isConsumer: true,
    isGuide: false
};
const JournalContext = React.createContext(initialState);

const apiTags = {
    trips: "getDoT_Trips1",
    catches: "getDoT_Catches1",
    spots: "getDoT_Spots1",
    reports: "getFishReports1",
    pois: "getDoT_POIs1",
    isLoading: "isLoading"
};

const pageTags = {
    trips: "trips",
    catches: "catches",
    spots: "spots",
    reports: "reports",
    pois: "pois",
    dashboard: "dashboard"
};

const postTypes = {
    trip: 100,
    spot: 101,
    catches: 102,
    report: 103
};

export default JournalContext;
export { apiTags, pageTags, initialState, postTypes };
