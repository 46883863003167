import React from 'react';

// Initializing the initial state for the context( if not, the build will break )
const initialState = {
	bookGuide: false,
	beGuide: false,
	whatIsGuidesly: false,
	articles: false,
	ourStory: false,
	isSystemMessage: false,
	addUserContext: () => { },
	setUser: () => { },
	init: () => { },
	addUserTokens: () => { },
}
const UserContext = React.createContext(initialState);

export default UserContext;
