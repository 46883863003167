const Axios = require("axios")

const axios = Axios.create({
	baseURL: process.env.GUIDESLY_WBS,
	timeout: 120000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		"X-APP-ID": "6046acd0-5c48-11ea-8f77-42010a8e00cf",
	}
})

module.exports = axios
