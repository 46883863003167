import React, { useState } from 'react'
import SearchContext, { initialState } from './SearchContext';

const SearchProvider = props => {
	const [searchQuery, setSearchQuery] = useState(initialState.searchQuery)
	return (
		<SearchContext.Provider
			value={{
				searchQuery, setSearchQuery
			}}
		>
			{props.children}
		</SearchContext.Provider>
	);
};
export default SearchProvider
