import React, { useState } from "react"
import UserContext from "./UserContext";
// import * as firebaseMessage from "../utils/firebaseMessage";
const UserProvider = (props) => {

	const [bookGuide, setBookGuide] = useState(false)
	const [beGuide, setBeGuide] = useState(false)
	const [whatIsGuidesly, setWhatIsGuidesly] = useState(false)
	const [articles, setArticles] = useState(false)
	const [whoWeAre, setWhoWeAre] = useState(false)
	const [account, setAccount] = useState(false)
	const [guideguid, setGuideguid] = useState("")
	const [guidename, setGuideName] = useState("")
	const [guideid, setGuideId] = useState("")
	const [toUser, setToUser] = useState("")
	const [awsToken, setAwsToken] = useState("")
	const [cognitoId, setCognitoId] = useState("")
	const [convId, setConvId] = useState("")
	const [openMsg, setOpenMsg] = useState("")
	const [MessageConversations, setMessageConversations] = useState([])
	const [userMessages, setUserMessages] = useState([])
	const [prevMessages, setPrevMessages] = useState([]);
	const [initCall, setInitCall] = useState(false);
	const [refreshMsg, setRefreshMsg] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isguest, setIsguest] = useState(false);
	const [list, setList] = useState(true);
	const [details, setDetails] = useState(false);
	const [newMessage, setNewMessage] = useState(false);
	const [newMesSearch, setNewMesSearch] = useState();
	const [listedGuides, setListedGuides] = useState();
	// const [userState, setUserState] = useState({});
	const [personalDetails, setPersonalDetails] = useState(false);
	const [active, setActive] = useState('guide');
	//System messages
	const [systemMessages, setSystemMessages] = useState([])
	const [isSystemMessage, setIsSystemMessage] = useState(false)
	const [faqArticles, setFaqArticles] = useState([])
	const [searchWaterBodies, setSearchWaterBodies] = useState([])
	const [searchState, setSearchState] = useState([])
	const [loadingMask, setLoadingMask] = useState(false)
	const [trips, setTrips] = useState([])
	const [promoCodes, setPromoCodes] = useState([])
	const [showArticlesTrigger, setShowArticlesTrigger] = useState(false)
	const [articleTriggeredAcrossSite, setArticleTriggeredAcrossSite] = useState(false)
	//rate n review
	const [showRateAndReview, setShowRateAndReview] = useState(false)
	const [messageLoad, setMessageLoad] = useState(true);
	const [rateReviewTrips, setRateReviewTrips] = useState([])
	const [reviewData, setReviewData] = useState([])
	const [showModal, setShowModal] = useState({ "show": false, "type": null })
	const [userState, setUserState] = useState({
		init: () => {
			const userStateDetail = userState;
			userStateDetail.userdata = null;
			userStateDetail.MessageCount = 0;
			userStateDetail.MessageConversations = [];
			userStateDetail.UnReadMessages = [];
			setUserState(userStateDetail);
		},
		addUserContext: (userData) => {
			const userStateDetail = userState;
			userStateDetail.userdata = userData;
			// Sentry.setUser({ id: userData.guideguid });
			userStateDetail.MessageConversations = [];
			userStateDetail.MessageCount = 0;
			userStateDetail.UnReadMessages = [];
			setUserState(userStateDetail);
		},

		addUserTokens: async (tokens) => {
			const userStateDetail = userState;
			userStateDetail.tokens = tokens;
			userStateDetail.MessageCount = 0;
			userStateDetail.MessageConversations = [];
			userStateDetail.gConversations = [];
			userStateDetail.UnReadMessages = [];
			userStateDetail.initialLoad = true;

			setUserState(userStateDetail);
			//await firebaseMessage.init(userStateDetail);
		},

		getUnReadMessageCount: () => {
			let returnValue = 0;
			// const userStateDetail = userState;

			// if (userStateDetail != null && userStateDetail != undefined
			// 	&& userStateDetail.gConversations != null && userStateDetail.gConversations != undefined) {
			// 	const conversations = Object.values(userStateDetail.gConversations);
			// 	conversations.forEach((conversation) => {
			// 		if (conversation.unread != undefined) returnValue += conversation.unread;
			// 	});
			// }

			return returnValue;
		},

		clear: async () => {
			//   await AsyncStorage.removeItem('userToken');
			//   await AsyncStorage.removeItem('isBiometric');
			//   await AsyncStorage.removeItem('isComingFromLogin');
			//   const userStateDetail = userState;
			//   userStateDetail.tokens = '';
			//   userStateDetail.userdata = '';
			//   userStateDetail.fcmToken = '';
			//   setUserState(userStateDetail);
			//   userStateDetail.initialLoad = true;
			//   userStateDetail.MessageCount = 0;
			//   userStateDetail.MessageConversations = [];
			//   userStateDetail.UnReadMessages = [];
		},
	});

	return (
		<UserContext.Provider value={{
			active, setActive,
			faqArticles, setFaqArticles,
			searchWaterBodies, setSearchWaterBodies,
			searchState, setSearchState,
			bookGuide, setBookGuide,
			beGuide, setBeGuide,
			whatIsGuidesly, setWhatIsGuidesly,
			articles, setArticles,
			whoWeAre, setWhoWeAre,
			account, setAccount,
			guideguid, setGuideguid,
			guidename, setGuideName,
			toUser, setToUser,
			awsToken, setAwsToken,
			cognitoId, setCognitoId,
			convId, setConvId,
			openMsg, setOpenMsg,
			MessageConversations, setMessageConversations,
			userState, setUserState,
			userMessages, setUserMessages,
			prevMessages, setPrevMessages,
			initCall, setInitCall,
			refreshMsg, setRefreshMsg,
			loading, setLoading,
			isguest, setIsguest,
			personalDetails, setPersonalDetails,
			systemMessages, setSystemMessages,
			isSystemMessage, setIsSystemMessage,
			loadingMask, setLoadingMask,
			trips, setTrips,
			promoCodes, setPromoCodes,
			showArticlesTrigger, setShowArticlesTrigger,
			articleTriggeredAcrossSite, setArticleTriggeredAcrossSite,
			showRateAndReview, setShowRateAndReview,
			rateReviewTrips, setRateReviewTrips,
			messageLoad, setMessageLoad,
			guideid, setGuideId,
			list, setList,
			details, setDetails,
			newMessage, setNewMessage,
			newMesSearch, setNewMesSearch,
			listedGuides, setListedGuides,
			showModal, setShowModal,
			reviewData, setReviewData

		}}
		>
			{props.children}
		</UserContext.Provider>
	)
}

export default UserProvider
